import { useState } from "react";

import { KeyboardArrowLeft } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";

import SearchBar from "shared/components/table/SearchBar";

const CollapsibleSearchBar = ({
  searchTerm,
  updateSearchTerm,
  searchBarExpandedState: [searchBarExpanded, setSearchBarExpanded] = [
    true,
    () => {},
  ],
}: {
  searchTerm: string;
  updateSearchTerm: (term: string) => void;
  searchBarExpandedState?: [boolean, (value: boolean) => void];
}) => {
  return (
    <Stack
      display="flex"
      direction={"row"}
      width={{
        xs: searchBarExpanded ? "100%" : "auto",
        sm: "auto",
      }}
      justifyContent={"flex-end"}
    >
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <IconButton onClick={() => setSearchBarExpanded(!searchBarExpanded)}>
          {searchBarExpanded ? (
            <KeyboardArrowLeft color="secondary" />
          ) : (
            <SearchIcon color="primary" />
          )}
        </IconButton>
      </Box>
      <Box
        display={{ xs: searchBarExpanded ? "block" : "none", sm: "flex" }}
        width={"inherit"}
      >
        <SearchBar
          searchTerm={searchTerm}
          onChange={updateSearchTerm}
          sx={{ parent: { width: "inherit" }, child: { width: "inherit" } }}
        />
      </Box>
    </Stack>
  );
};

const DashboardHeader = ({
  title,
  searchTerm,
  updateSearchTerm,
  dropdownMenu,
}: {
  title: string;
  searchTerm: string;
  updateSearchTerm: (term: string) => void;
  dropdownMenu?: JSX.Element;
}) => {
  const [searchBarExpanded, setSearchBarExpanded] = useState<boolean>(false);
  return (
    <Grid container flexDirection={"row"} justifyContent={"space-between"}>
      <Grid item order={1}>
        <Typography
          variant="h6"
          display={{ xs: searchBarExpanded ? "none" : "block", sm: "block" }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        order={dropdownMenu ? 3 : 2}
        xs={searchBarExpanded ? 12 : "auto"}
        sm={"auto"}
      >
        <CollapsibleSearchBar
          searchTerm={searchTerm}
          updateSearchTerm={updateSearchTerm}
          searchBarExpandedState={[searchBarExpanded, setSearchBarExpanded]}
        />
      </Grid>
      {dropdownMenu && (
        <Grid
          item
          order={{ xs: 2, md: 3 }}
          display={{ xs: searchBarExpanded ? "none" : "block", sm: "block" }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{ maxWidth: { xs: "9rem", sm: "100%" } }}
          >
            {dropdownMenu}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default DashboardHeader;
