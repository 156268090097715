import {
  POST, GET, PUT, DELETE,
  DEFAULT_TIMEOUT,
  getAuthenticatedRequestHeaders,
  OpenAPIFetchOptions,
} from "../../services/serviceUtils";

export async function create(
  options: OpenAPIFetchOptions<typeof POST<"/vehicles">>
) {
  return await POST("/vehicles", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function decodeLicensePlate(
  options: OpenAPIFetchOptions<typeof GET<"/vehicles/decodePlate">>
) {
  return await GET("/vehicles/decodePlate", {
    ...options,
    headers: await getAuthenticatedRequestHeaders({ "optionalAuthHeaders": true }),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function decodeVIN(
  options: OpenAPIFetchOptions<typeof GET<"/vehicles/decodeVIN">>
) {
  return await GET("/vehicles/decodeVIN", {
    ...options,
    headers: await getAuthenticatedRequestHeaders({ "optionalAuthHeaders": true }),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function defaultImage(
  options: OpenAPIFetchOptions<typeof GET<"/vehicles/defaultImage">>
) {
  return await GET("/vehicles/defaultImage", {
    ...options,
    headers: await getAuthenticatedRequestHeaders({ "optionalAuthHeaders": true }),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function getMakes(
  options: OpenAPIFetchOptions<typeof GET<"/vehicles/makes">>
) {
  return await GET("/vehicles/makes", {
    ...options,
    headers: await getAuthenticatedRequestHeaders({ "optionalAuthHeaders": true }),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function getModels(
  options: OpenAPIFetchOptions<typeof GET<"/vehicles/models/{makeId}">>
) {
  return await GET("/vehicles/models/{makeId}", {
    ...options,
    headers: await getAuthenticatedRequestHeaders({ "optionalAuthHeaders": true }),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function verifyCarNicknameAvailability(
  options: OpenAPIFetchOptions<typeof GET<"/vehicles/verifyCarNicknameAvailability">>
) {
  return await GET("/vehicles/verifyCarNicknameAvailability", {
    ...options,
    headers: await getAuthenticatedRequestHeaders({ "optionalAuthHeaders": true }),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function get(
  options: OpenAPIFetchOptions<typeof GET<"/vehicles/{vehicleId}">>
) {
  return await GET("/vehicles/{vehicleId}", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function update(
  options: OpenAPIFetchOptions<typeof PUT<"/vehicles/{vehicleId}">>
) {
  return await PUT("/vehicles/{vehicleId}", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function addEvoxImageUrl(
  options: OpenAPIFetchOptions<typeof PUT<"/vehicles/{vehicleId}/evoxImageUrl">>
) {
  return await PUT("/vehicles/{vehicleId}/evoxImageUrl", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function deleteVehicles(
  options: OpenAPIFetchOptions<typeof DELETE<"/vehicles/{vehicleId}/users/{userId}">>
) {
  return await DELETE("/vehicles/{vehicleId}/users/{userId}", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function deleteCarImage(
  options: OpenAPIFetchOptions<typeof DELETE<"/vehicles/{vehicleId}/users/{userId}/deleteUsersVehicleImage">>
) {
  return await DELETE("/vehicles/{vehicleId}/users/{userId}/deleteUsersVehicleImage", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function updateCarImage(
  options: OpenAPIFetchOptions<typeof PUT<"/vehicles/{vehicleId}/users/{userId}/uploadUsersVehicleImage">>
) {
  return await PUT("/vehicles/{vehicleId}/users/{userId}/uploadUsersVehicleImage", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}
