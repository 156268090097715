import { usePathname, useSearchParams } from "next/navigation";
import { useRouter } from "next/router";

type FleetSearchModel = {
  search: string;
};

export default function useFleetSearch() {
  const router = useRouter();
  const params = useSearchParams();
  const searchTerm = params.get("search") ?? "";
  const pathName = usePathname();
  const fleetSearch = {
    search: searchTerm,
  };

  const updateFleetSearch = (newSearch: FleetSearchModel) => {
    const newParams = new URLSearchParams(params);

    for (const [key, value] of Object.entries(newSearch)) {
      if (value) {
        newParams.set(key, value.toString());
      } else {
        newParams.delete(key);
      }
    }
    router.replace(`${pathName}?${newParams.toString()}`);
  };

  return {
    fleetSearch,
    updateFleetSearch,
  };
}
