import { useCallback } from "react";

import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import {
  GridFilterItem,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { useQueryState } from "nuqs";

import { Order_By } from "../../generated/graphql";
import StyledLink from "../StyledLink";
import VehicleImage, {
  VehicleImageHeight,
  VehicleImageWidth,
} from "../vehicles/VehicleImage";

export const createStyledLinkCell = (
  hrefPattern: (params: GridRenderCellParams) => string
) =>
  function StyledLinkCell(params: GridRenderCellParams) {
    return <StyledLink href={hrefPattern(params)}>{params.value}</StyledLink>;
  };

export function renderVehicleImageCell(
  params: GridRenderCellParams<{
    image: {
      vehicleId: number | null | undefined;
      imageUrl: string | null | undefined;
    };
  }>
) {
  return (
    <Box pt={1}>
      <VehicleImage
        vehicleId={params.value.vehicleId}
        imageUrl={params.value.imageUrl}
        widthUsage={VehicleImageWidth.thumbnail}
        heightUsage={VehicleImageHeight.thumbnail}
        sx={{ transform: "scaleX(-1)" }}
      />
    </Box>
  );
}

type Tag = {
  id: number;
  key: string;
};
export function renderTagsCell(
  params: GridRenderCellParams<{
    vehicleTags: {
      tags: Tag[] | undefined;
    };
  }>
) {
  if (!params.value || !params.value.tags || params.value.tags.length === 0) {
    return null;
  }

  return (
    <Stack direction={"row"} gap={1} pt={1}>
      {params.value.tags.map((tag: Tag) => {
        return <Chip key={tag.id} label={tag.key} variant="outlined" />;
      })}
    </Stack>
  );
}

export const useDataGridSortHandler = (defaultSort: {
  field: string;
  direction: Order_By;
}) => {
  const [sortField, setSortField] = useQueryState("sortField", {
    defaultValue: defaultSort.field,
  });
  const [sortDirection, setSortDirection] = useQueryState("sortDirection", {
    defaultValue: defaultSort.direction,
  });

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      const model = sortModel?.[0];
      let direction = defaultSort.direction;
      if (model?.sort) {
        direction = model?.sort === "desc" ? Order_By.Desc : Order_By.Asc;
      }

      setSortField(model?.field ?? defaultSort.field);
      setSortDirection(direction);
    },
    [defaultSort.direction, defaultSort.field, setSortField, setSortDirection]
  );

  return {
    sortField,
    sortDirection,
    handleSortModelChange,
  };
};

export const ColumnFilter = ({
  item,
  applyValue,
  label,
  options,
}: {
  item: GridFilterItem;
  applyValue: (value: GridFilterItem) => void;
  label: string;
  options: string[];
}) => {
  return (
    <FormControl fullWidth variant="standard">
      <InputLabel>{label}</InputLabel>
      <Select
        value={item.value || ""}
        onChange={(event) => {
          applyValue({ ...item, value: event.target.value });
        }}
        label={label}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
