import {
  endOfMonth,
  formatISO,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subMonths,
  subWeeks,
} from "date-fns";
import humanizeDuration from "humanize-duration";
// eslint-disable-next-line no-restricted-imports
import moment from "moment";

export type DateRange = {
  from: string;
  to: string;
};

export const getToday = (): DateRange => {
  const now = new Date();
  const from = formatISO(startOfDay(now));
  const to = formatISO(now);
  return { from, to };
};

export const getLast7Days = (): DateRange => {
  const now = new Date();
  const from = formatISO(subWeeks(now, 1));
  const to = formatISO(now);
  return { from, to };
};

export const getPreviousMonth = (): DateRange => {
  const now = new Date();
  const from = formatISO(startOfMonth(subMonths(now, 1)));
  const to = formatISO(endOfMonth(subMonths(now, 1)));
  return { from, to };
};

export const getWeekToDate = (): DateRange => {
  const now = new Date();
  const from = formatISO(startOfWeek(now));
  const to = formatISO(now);
  return { from, to };
};

export const getMonthToDate = (): DateRange => {
  const now = new Date();
  const from = formatISO(startOfMonth(now));
  const to = formatISO(now);
  return { from, to };
};

export const getQuarterToDate = (): DateRange => {
  const now = new Date();
  const from = formatISO(startOfQuarter(now));
  const to = formatISO(now);
  return { from, to };
};

export const getYearToDate = (): DateRange => {
  const now = new Date();
  const from = formatISO(startOfYear(now));
  const to = formatISO(now);
  return { from, to };
};

export function getCarYears(minYear = 1980) {
  const currentYear = new Date().getFullYear();
  const maxYear = currentYear + 1;

  const years = Array.from(
    { length: maxYear - minYear },
    (_, i) => maxYear - i
  );

  return { years, currentYear, maxYear };
}

export function readableDuration(seconds: number): string {
  const humanize = humanizeDuration.humanizer({
    round: true,
    largest: 2,
    language: "shortEn",
    languages: {
      shortEn: {
        y: () => "y",
        mo: () => "mo",
        w: () => "wk",
        d: () => "d",
        h: () => "h",
        m: () => "min",
        s: () => "sec",
        ms: () => "ms",
      },
    },
  });

  const millisecondsInSecond = 1000;
  const durationInMilliseconds = seconds * millisecondsInSecond;

  return humanize(durationInMilliseconds);
}

export const prettyMonthDayYear = (
  date: string | undefined | null | Date,
  short?: boolean
) => {
  if (!date) {
    return "";
  }
  return moment(date).format(short ? "MMM D, YYYY" : "MMMM D, YYYY");
};

export const getPrettyTimeWithoutDate = (
  date: Date | string | null | undefined
): string => {
  if (!date) {
    return "";
  }
  return moment(date).format("h:mm A");
};
