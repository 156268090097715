import { CSSProperties, useEffect, useState } from "react";

import { Box } from "@mui/material";
import Image from "next/legacy/image";

import { DEFAULT_VEHICLE_IMAGE_URL } from "../../../../../backend/src/evox/placeholderCarUrls";
import { addEvoxImageUrl } from "../../generated/clients/vehicles.client";

const CLOUDFRONT_HOSTNAME = "d2ivfcfbdvj3sm.cloudfront.net";

const FIREBASE_DOMAIN = "firebasestorage.googleapis.com";

/**
 * @public
 */
export enum VehicleImageWidth {
  card = 216,
  thumbnail = 62,
  default = 294,
}

/**
 * @public
 */
export enum VehicleImageHeight {
  card = 150,
  thumbnail = 32,
  default = 180,
}

const VehicleImage = ({
  imageUrl,
  vehicleId,
  sx,
  alt,
  widthUsage = VehicleImageWidth.default,
  heightUsage = VehicleImageHeight.default,
}: {
  imageUrl: string | null | undefined;
  vehicleId: number | null | undefined;
  sx?: CSSProperties | undefined;
  alt?: string;
  widthUsage?: VehicleImageWidth;
  heightUsage?: VehicleImageHeight;
}) => {
  const [vehicleImg, setVehicleImg] = useState(imageUrl);
  const getEvoxImageUrl = async (vehicleId: number) => {
    const res = await addEvoxImageUrl({ params: { path: { vehicleId } } });
    if (res?.data?.imageUrl) {
      setVehicleImg(res.data.imageUrl);
      return;
    }
    setVehicleImg(DEFAULT_VEHICLE_IMAGE_URL);
  };

  useEffect(() => {
    if (vehicleId && (!imageUrl || imageUrl === DEFAULT_VEHICLE_IMAGE_URL)) {
      getEvoxImageUrl(vehicleId);
    }
  }, [imageUrl, vehicleId]);

  useEffect(() => {
    setVehicleImg(imageUrl);
  }, [imageUrl]);

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      height={heightUsage}
      width={widthUsage}
      justifyContent={"center"}
      borderRadius={2}
      position="relative"
      sx={{
        ...sx,
        backgroundColor: "action.hover",
      }}
    >
      <Image
        src={vehicleImg ?? DEFAULT_VEHICLE_IMAGE_URL}
        alt={alt ?? "Vehicle image"}
        layout="fill"
        objectFit="cover"
        // unoptimized images are not proxied through vercel, so we should only try to proxy images
        // that we've added to the nextjs.config.js and are known hosts
        unoptimized={
          !(
            imageUrl?.includes(FIREBASE_DOMAIN) ||
            imageUrl?.includes(CLOUDFRONT_HOSTNAME)
          )
        }
      />
    </Box>
  );
};

export default VehicleImage;
