import { Dispatch, SetStateAction } from "react";

import { LocalOffer } from "@mui/icons-material";
import { Box, Button, Tooltip, Typography } from "@mui/material";

import { useTags } from "../../providers/TagsProvider";

const ToggleTagsButton = ({
  showTags,
  setShowTags,
}: {
  showTags: boolean;
  setShowTags: Dispatch<SetStateAction<boolean>>;
}) => {
  const { fleetTagsList } = useTags();

  return (
    <Tooltip
      title={
        !fleetTagsList?.length ? (
          <Typography variant="body2">
            To create tags, go to your Tags tab in Settings
          </Typography>
        ) : (
          ""
        )
      }
    >
      <Box>
        <Button
          disabled={!fleetTagsList?.length}
          onClick={() => setShowTags((prev) => !prev)}
          variant="text"
          endIcon={<LocalOffer />}
        >
          {showTags ? "Hide Tags" : "Show Tags"}
        </Button>
      </Box>
    </Tooltip>
  );
};

export default ToggleTagsButton;
