export const DEFAULT_VEHICLE_IMAGE_URL =
  "https://firebasestorage.googleapis.com/v0/b/serviceupios.appspot.com/o/carPlaceholders%2Fcar_v2.png?alt=media&token=b14a17eb-d1b2-469e-819c-e7c3746d95c7";

export const placeholderUrls = {
  SUT: "https://firebasestorage.googleapis.com/v0/b/serviceupios.appspot.com/o/carPlaceholders%2Fbronco.png?alt=media&token=29f7aee5-7eb0-4e3f-98e1-b1ec53543250",
  pickup:
    "https://firebasestorage.googleapis.com/v0/b/serviceupios.appspot.com/o/carPlaceholders%2Ftruck.png?alt=media&token=ebfe19c6-362f-4211-b576-9237d0ad4aa2",
  minivan:
    "https://firebasestorage.googleapis.com/v0/b/serviceupios.appspot.com/o/carPlaceholders%2Fminivan.png?alt=media&token=e214aa75-b58d-4e63-bbc2-ac277572bf77",
  ["cargo van"]:
    "https://firebasestorage.googleapis.com/v0/b/serviceupios.appspot.com/o/carPlaceholders%2Fdelivery_van.png?alt=media&token=ca8c6743-7343-4be6-84e1-ea3ff31b6889",
  ["passenger van"]:
    "https://firebasestorage.googleapis.com/v0/b/serviceupios.appspot.com/o/carPlaceholders%2Fbus.png?alt=media&token=5c232886-5da2-420e-9db6-5d9b386b688a",
  SUV: "https://firebasestorage.googleapis.com/v0/b/serviceupios.appspot.com/o/carPlaceholders%2Fsuv.png?alt=media&token=6d93fa81-e4d4-4160-a75a-f560966ef81a",
  car: DEFAULT_VEHICLE_IMAGE_URL,
} as const;
